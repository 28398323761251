import { connect } from "react-redux";

import DownloadWallet from "./DownloadWallet";

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  {}
)(DownloadWallet);
